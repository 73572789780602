import { useNavigate } from 'react-router-dom';
import classes from './ScheduleDetailsCard.module.css'
import DetailsButton from './DetailsButton';

const ScheduleDetailsCard = ({schedule, showButton, buttonText}) => {
    let navigate = useNavigate();
    return(
        <div className={classes.scheduledLessonsCard} onClick={() => navigate({pathname: `/lessons/live-lesson/${schedule.id}`})} role='button'>
            {schedule && <div className='d-flex flex-column mb-2 align-items-center'><h4>{schedule.title}</h4>
            <h5>{schedule.desc}</h5>
            {showButton && <DetailsButton onClick={(event) => { event.stopPropagation(); navigate({pathname: `details/${schedule.id}`})}} buttonText={buttonText}/>}
            </div>}
            <div>{schedule ? "This lesson will start in 3 minutes" : "You have no live lessons scheduled  right now"}</div>
        </div>
    )
}

export default ScheduleDetailsCard