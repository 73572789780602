import React, { createContext, useContext, useState, useEffect } from 'react';
import { login, logout, getCurrentUser } from '../services/auth';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('authToken'));

  const logout = () => {
    Cookies.remove('authToken');
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        console.log("CURRENT USERRRR", currentUser)
        setUser(currentUser);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();


    const checkTokenExpiration = () => {
      const token = Cookies.get('authToken');
      if (!token) {
        setIsAuthenticated(false);
      }
    };

    const interval = setInterval(checkTokenExpiration, 1000);

    return () => clearInterval(interval);

    
  }, []);

  const handleLogin = async (email, password) => {
    const loggedInUser = await login(email, password);
    setIsAuthenticated(true);
    setUser(loggedInUser);
  };

  const handleLogout = async () => {
    await logout();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, handleLogin, handleLogout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);