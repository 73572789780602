import SearchBar from '../UI/SearchBar'
import tabeleClasses from '../UI/Table.module.css'
import SortItems from '../UI/SortItems'
import classes from './Classes.module.css'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '../../icons/CloseIcon'
import Overlay from '../UI/Overlay'
import { useEffect, useState } from 'react'
import ModalCard from '../UI/ModalCard/ModalCard'
import InputField from '../UI/InputField'
import { createClass } from '../../services/classes'
import { useSchools } from '../../context/SchoolsContext'
import { fetchSchools } from '../../services/schools'
import { useClasses } from '../../context/ClassesContext'


const tableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Learners",
        width: '100px'
    },
    {
        title: "Avg. Grade",
        width: '100px'
    },
    {
        title: "Assigned by",
        width: '150px'
    },
    {
        title: "Tests assigned",
        width: '150px'
    },
    {
        title: "Actions",
        width: '100px'
    } 
]

const tableContent = [
    {
        name: "9th Grade",
        learner: "32",
        avgGrade: "C-",
        assignedBy: "Admin name",
        testsAssigned: "30",
        id: 1
    },
    {
        name: "9th Grade",
        learner: "32",
        avgGrade: "C-",
        assignedBy: "Admin name",
        testsAssigned: "30",
        id: 2
    },
    {
        name: "9th Grade",
        learner: "32",
        avgGrade: "C-",
        assignedBy: "Admin name",
        testsAssigned: "30",
        id: 2
    },
    {
        name: "9th Grade",
        learner: "32",
        avgGrade: "C-",
        assignedBy: "Admin name",
        testsAssigned: "30",
        id: 3
    },
    {
        name: "9th Grade",
        learner: "32",
        avgGrade: "C-",
        assignedBy: "Admin name",
        testsAssigned: "30",
        id: 4
    },
    {
        name: "9th Grade",
        learner: "32",
        avgGrade: "C-",
        assignedBy: "Admin name",
        testsAssigned: "30",
        id: 5
    },
    {
        name: "9th Grade",
        learner: "32",
        avgGrade: "C-",
        assignedBy: "Admin name",
        testsAssigned: "30",
        id: 6
    },
    {
        name: "9th Grade",
        learner: "32",
        avgGrade: "C-",
        assignedBy: "Admin name",
        testsAssigned: "30",
        id: 7
    },
    {
        name: "9th Grade",
        learner: "32",
        avgGrade: "C-",
        assignedBy: "Admin name",
        testsAssigned: "30",
        id: 8
    }
]




const tabs = [
    "Assessments graded","Assessments not graded"
 ]

const Classes = () => {

    const [showOverlay, setShowOverlay] = useState(false)
    const [selectedGrade, setSelectedGrade] = useState('')
    const [selectedSchool, setSelectedSchool] = useState('')
    const [nameOfClass, setNameofClass] = useState('')

    const { schools } = useSchools()
    const { schoolClasses, getClasses } = useClasses()

    let navigate = useNavigate();



    const handleChange = (event) => {
        setSelectedGrade(event.target.value);
      };

      const submitHandler = async () => {
        const result = await createClass({name: nameOfClass, grade: selectedGrade, school: selectedSchool})
        if(result === "success"){
            getClasses()
        }
        setSelectedGrade("")
        setSelectedSchool("")
        setNameofClass("")
        setShowOverlay(false)
      }

    return(
        <div className={classes.main}>
            <SearchBar placeholder="Search classes"/>
            <div className='d-flex align-items-center justify-content-between'>
                <div></div>
                <button onClick={() => setShowOverlay(true)} style={{width: 200}}>Add Class</button>
            </div>
            
            <div className='mt-4'>
            <SortItems heading="Classes"/>
           <table className={`w-100 mt-3 ${tabeleClasses.learnerTable}`}>
                <tr>
                {tableHeader.map((item, i) => (
                    <th style={{width: item.width}} key={i}>{item.title}</th>
                ))}
                </tr>
                {schoolClasses.map((item, i) => (<tr>
                    <td><div className={tabeleClasses.removeButton} onClick={() => navigate({pathname: `grade/${item.id}`})} role='button' key={i}>{item.name}</div></td>
                    <td>{item.learners?.length}</td>
                    <td>{item.avgGrade}</td>
                    <td>{`${item.user_created?.first_name} ${item.user_created?.last_name}`}</td>
                    <td>{item.testsAssigned}</td>
                    <td><div className={tabeleClasses.removeButton} role='button'>ASSIGN TEST</div></td>
                </tr>))}
                </table>
                </div>
                {showOverlay && <Overlay onClick={() => setShowOverlay(false)}>
                    <ModalCard title="Add new class" onClose={() => setShowOverlay(false)}>
                        <div className='d-flex flex-column align-items-center justify-content-center gap-4 mt-5'>
                            <InputField label="Name" type="text" onChange={(e) => setNameofClass(e.target.value)} value={nameOfClass}/>
                            <InputField label="Grade" >
                            <select value={selectedGrade} onChange={handleChange}>
                                <option value="">--Select a Grade--</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                            </select>
                            </InputField>
                            <InputField label="School" >
                            <select value={selectedSchool} onChange={(e) => setSelectedSchool(e.target.value)}>
                                <option value="">--Select a School--</option>
                                {schools.map((item, i) => (<option value={item.id} key={i}>{item.name}</option>))}
                            </select>
                            </InputField>
                            <button onClick={submitHandler}>Submit</button>
                        </div>
                        
                    </ModalCard>
                </Overlay>}
        </div>
    )
}

export default Classes