import SearchBar from "../UI/SearchBar";

import SortItems from "../UI/SortItems";
import classes from "./ResourceLibrary.module.css";
import { useFetcher, useNavigate } from "react-router-dom";
import InfoCard from "../UI/InfoCard";
import { useEffect, useState } from "react";
import { fetchFolders } from "../../services/resource";

const tableHeader = [
  {
    title: "Name",
    width: "auto",
  },
  {
    title: "Type",
    width: "100px",
  },
];

const tableContent = [
  {
    name: "Resource name",
    type: "Technology",
    id: 1,
  },
  {
    name: "Resource name",
    type: "Technology",
    id: 2,
  },
  {
    name: "Resource name",
    type: "Technology",
    id: 3,
  },
  {
    name: "Resource name",
    type: "Technology",
    id: 4,
  },
  {
    name: "Resource name",
    type: "Technology",
    id: 5,
  },
  {
    name: "Resource name",
    type: "Technology",
    id: 6,
  },
  {
    name: "Resource name",
    type: "Technology",
    id: 7,
  },
  {
    name: "Resource name",
    type: "Technology",
    id: 8,
  },
  {
    name: "Resource name",
    type: "Technology",
    id: 9,
  },
];

const infoList = [
  {
    title: "Category name",
    subText: "5 resources",
    id: 1,
  },
  {
    title: "Category name",
    subText: "5 resources",
    id: 2,
  },
  {
    title: "Category name",
    subText: "5 resources",
    id: 3,
  },
  {
    title: "Category name",
    subText: "5 resources",
    id: 4,
  },
];

const ResourceLibrary = () => {
  const [folders, setFolders] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    getFolders();
  }, []);

  const getFolders = async () => {
    const response = await fetchFolders();
    setFolders(response);
  };

  return (
    <div className={classes.main}>
      <SearchBar placeholder="Search resources" />
      <div className="row mt-4">
        {folders.map((item, i) => (
          <div className="col-3 mb-3" key={i}>
            <InfoCard title={item.name} subText="Folder" link={item.id} />
          </div>
        ))}
      </div>
      {/* <div className="mt-4">
        <SortItems heading="Resources" />
        <table className={`w-100 mt-3 ${tabeleClasses.learnerTable}`}>
          <tr>
            {tableHeader.map((item, i) => (
              <th style={{ width: item.width }} key={i}>
                {item.title}
              </th>
            ))}
          </tr>
          {tableContent.map((item, i) => (
            <tr key={i}>
              <td>
                <div
                  className={tabeleClasses.removeButton}
                  onClick={() => navigate({ pathname: `${item.id}` })}
                  role="button"
                >
                  {item.name}
                </div>
              </td>
              <td>{item.type}</td>
            </tr>
          ))}
        </table>
      </div> */}
    </div>
  );
};

export default ResourceLibrary;
