// src/context/LessonsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchLessons } from '../services/lessons';

const LessonsContext = createContext();

export const LessonsProvider = ({ children }) => {
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
    

  //   getLessons();
  // }, []);

  const getLessons = async () => {
    try {
      const lessonsData = await fetchLessons();
      setLessons(lessonsData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LessonsContext.Provider value={{ lessons, loading, getLessons, error }}>
      {children}
    </LessonsContext.Provider>
  );
};

export const useLessons = () => useContext(LessonsContext);