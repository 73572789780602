// src/services/lessons.js
import { createItem, readItems, rest } from '@directus/sdk';
import directus from './directus';
import Cookies from 'js-cookie';

const client = directus.with(rest());

export const fetchLessons = async () => {
  try {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('User not authenticated');
    }

    const lessons = await client.request(readItems('lessons', {fields: ["*.*"]}, {access_token: token} ));

    console.log("LESSONSSSS", lessons)
    return lessons;
  } catch (error) {
    throw new Error('Failed to fetch lessons: ' + error.message);
  }
};

export const fetchLessonsByClass = async (classId) => {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('User not authenticated');
    }

    const lessons = await client.request(readItems('lessons',{filter: {class: {_eq: classId}}} ));

    console.log("LESSONSSSS", lessons)
    return lessons;
};

export const createLesson = async (lessonData) => {
    console.log("LESSON DATA TO SEND", lessonData)
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('User not authenticated');
    }
    const result = await client.request(createItem('lessons', lessonData, {access_token: token}));
    return result;
};