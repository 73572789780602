import classes from './InfoDetailsCard.module.css'

const InfoDetailsCard = ({title}) => {
    return(
        <div className={classes.main}>
            <h3>{title}</h3>
            <div className={`mt-3 ${classes.descriptionCard}`}>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse odio lacus, tempus sit amet pharetra id, rutrum quis orci. Donec dignissim cursus eros, et dapibus nibh tempus a. Quisque dignissim molestie augue id rhoncus. Cras ut iaculis sem, faucibus gravida lectus. Cras quis nunc a dui convallis feugiat. Duis porta cursus interdum. Ut venenatis venenatis leo, ac imperdiet eros suscipit non. Quisque tincidunt nibh venenatis tortor egestas finibus. Morbi lobortis laoreet urna, ut ultricies massa laoreet in. Aliquam non pulvinar purus.</p>
            <p>Sed eleifend ligula ac elit imperdiet, nec maximus massa varius. Praesent condimentum fermentum enim, ac cursus turpis varius nec. Fusce commodo sapien vitae elit pretium hendrerit. Mauris sodales felis lacus, vel eleifend nulla fermentum ut. Pellentesque posuere venenatis hendrerit. Praesent nibh elit, iaculis non nulla a, dapibus ornare metus. Etiam nec mauris urna. Nunc tincidunt, ipsum nec venenatis maximus, nisi dui convallis metus, id gravida erat elit eu leo. Donec ac lobortis lacus, non aliquam ipsum. Maecenas a magna non justo fermentum fermentum ac a arcu. Pellentesque sed ante sit amet enim tempor pretium in sed nisi. In ut efficitur nisl, eu molestie dui. Praesent molestie vehicula hendrerit. Fusce vulputate, velit a condimentum ullamcorper, velit massa suscipit libero, eget feugiat velit mi faucibus arcu.</p> 
            </div>
        </div>
    )
}

export default InfoDetailsCard