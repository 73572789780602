import InfoDetailsCard from '../../UI/InfoDetailsCard'
import classes from './CopyrightNotice.module.css'


const CopyrightNotice = () => {

    return(
        <div className={classes.main}>
            <InfoDetailsCard title="Copyright notice"/>
        </div>
    )
}

export default CopyrightNotice