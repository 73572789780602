import SearchBar from '../../UI/SearchBar'
import tabeleClasses from '../../UI/Table.module.css'
import SortItems from '../../UI/SortItems'
import classes from './HelpCategory.module.css'
import { useNavigate } from 'react-router-dom'


const tableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Category",
        width: '150px'
    } 
]

const tableContent = [
    {
        name: "Article name",
        category: "Category name",
        id: 1
    },
    {
        name: "Article name",
        category: "Category name",
        id: 2
    },
    {
        name: "Article name",
        category: "Category name",
        id: 3
    },
    {
        name: "Article name",
        category: "Category name",
        id: 4
    },
    {
        name: "Article name",
        category: "Category name",
        id: 5
    },
    {
        name: "Article name",
        category: "Category name",
        id: 6
    },
    {
        name: "Article name",
        category: "Category name",
        id: 7
    },
    {
        name: "Article name",
        category: "Category name",
        id: 8
    },
    {
        name: "Article name",
        category: "Category name",
        id: 9
    },
    {
        name: "Article name",
        category: "Category name",
        id: 10
    }
]

const infoList = [
    {
        title: "Help category",
        subText: "5 resources",
        id: 1
    },
    {
        title: "Help category",
        subText: "5 resources",
        id: 2
    },
    {
        title: "Help category",
        subText: "5 resources",
        id: 3
    },
    {
        title: "Help category",
        subText: "5 resources",
        id: 4
    },
]

const HelpCategory = () => {

    let navigate = useNavigate();

    return(
        <div className={classes.main}>
             <div className={` d-flex align-items-center gap-4`}><SearchBar placeholder="Search resources" /> <div className={classes.addButton} role='button'>I need help</div></div>
            <div className='mt-4'>
            <SortItems heading="Category name"/>
           <table className={`w-100 mt-3 ${tabeleClasses.learnerTable}`}>
                <tr>
                {tableHeader.map((item, i) => (
                    <th style={{width: item.width}} key={i}>{item.title}</th>
                ))}
                </tr>
                {tableContent.map((item, i) => (<tr>
                    <td><div className={tabeleClasses.removeButton} onClick={() => navigate({pathname: `${item.id}`})} role='button'>{item.name}</div></td>
                    <td>{item.category}</td>
                </tr>))}
                </table>
                </div>
        </div>
    )
}

export default HelpCategory