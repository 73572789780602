import classes from './AboutLessonCard.module.css'

const AboutLessonCard = ({ title, value }) => {
  return (
    <div className="col-4">
      <div className={classes.aboutLessonCard}>
        <p>{title}</p>
        <h5>{value}</h5>
      </div>
    </div>
  );
}

export default AboutLessonCard;
