import { useState } from 'react'
import classes from './Table.module.css'
import Switcher from './switcher/Switcher'

const featuresHeader = [
    {
        title: "Option name",
        width: 'auto'
    },
    {
        title: "Status",
        width: '150px'
    }
]

const featuresTableContent = [
    {
        name: "Blackboard",
        tag: "blackboard",
        status: true,
    },
    {
        name: "Raise hand to talk",
        tag: "raise",
        status: true,
    },
    {
        name: "Video chat",
        tag: "video",
        status: true,
    },
    {
        name: "Voice chat",
        tag: "voice",
        status: false,
    }
]

const FeacturesTab = () => {

    const [blackboard, setBlackboard] = useState(true)
    const [raise, setRaise]  = useState(true)
    const [video, setVideo]  = useState(true)
    const [voice, setVoice]  = useState(false)

    return(
        <table className={`w-100 ${classes.learnerTable}`}>
            <tr>
            {featuresHeader.map((item, i) => (
                <th style={{width: item.width}} key={i}>{item.title}</th>
            ))}
            </tr>
            {featuresTableContent.map((item, i) => (<tr>
                <td>{item.name}</td>
            
                <td><Switcher value={item.tag === "blackboard" ? blackboard : item.tag === "raise" ? raise : item.tag === "video" ? video : item.tag === "voice" && voice }
                    onChange={val => {
                        if(item.tag === "blackboard"){
                            setBlackboard(val)
                        }else if(item.tag === "raise"){
                            setRaise(val)
                        }else if(item.tag === "video"){
                            setVideo(val)
                        }else if(item.tag === "voice"){
                            setVoice(val)
                        }
                    console.log('STATUS VALUE', val)
                    }}/></td>
            </tr>))}
        </table>
    )
}

export default FeacturesTab