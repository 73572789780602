// src/services/lessons.js
import { createItem, readItem, readItems, rest, updateItem } from '@directus/sdk';
import directus from './directus';
import Cookies from 'js-cookie';
import { Bounce, toast } from 'react-toastify';

const client = directus.with(rest());


export const fetchClasses = async () => {
  try {

    const classes = await client.request(readItems('classes', {fields: ["id", "name", "grade", "learners", "user_created.*"]}));
    return classes;
  } catch (error) {
    throw new Error('Failed to fetch classes: ' + error.message);
  }
};

export const createClass = async (lessonData) => {
  try {
    const token = Cookies.get('authToken');


    if (!token) {
      throw new Error('User not authenticated');
    }

    const result = await client.request(createItem('classes', lessonData,  {access_token: token}));
    console.log("results", result)
    toast.success("Class added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      toastId: "session-expired"
      });
    return "success";
  } catch (error) {
    console.log("Error", error)
    toast.error(error.errors[0].message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      toastId: "session-expired"
      });
    return "error"
    // throw new Error('Failed to create class: ' + error);
  }
};

export const fetchClassesById = async (classId) => {
  try {

    console.log("SINGLE CLASS ID", classId)

    const classes = await client.request(readItem('classes', classId, {fields:["*.*"]}));

    console.log("SINGLE CLASS", classes)
    return classes;
  } catch (error) {
    throw new Error('Failed to fetch classes: ' + error.message);
  }
};


export const updateClass = async (id, classData) => {

    const token = Cookies.get('authToken');

    console.log("ID TO UPDATE", id)
    console.log("DATA TO UPDATE WITH", classData)
    if (!token) {
      throw new Error('User not authenticated');
    }

    await client.request(updateItem('classes', id, {learners: classData},  {access_token: token}));
   
};

