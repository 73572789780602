// src/services/lessons.js
import { createItem, readItems, rest } from '@directus/sdk';
import directus from './directus';
import Cookies from 'js-cookie';

const client = directus.with(rest());

export const fetchSchools = async () => {
  try {
    console.log("SCHOOLS")
    // const token = Cookies.get('authToken');

    // if (!token) {
    //   throw new Error('User not authenticated');
    // }

    const schools = await client.request(readItems('schools'));

    console.log("SCHOOLS", schools)
    return schools;
  } catch (error) {
    throw new Error('Failed to fetch schools: ' + error.message);
  }
};