import { useState } from 'react'
import classes from './Accordion.module.css'
import BulletIcon from '../../icons/BulletIcon'
import BoardIcon from '../../icons/BoardIcon'

const Accordion = ({dropList}) => {
    const [clickedTab, setClickedTab] = useState(null)
    return(
        <div className={`mt-3 d-flex flex-column gap-3`}>
                    {dropList.map((item, i) => (<div className={`${classes.updateListCard}`}  key={i} style={{backgroundColor: clickedTab === i && '#FAFAFA'}}>
                        <div className={`${classes.innerCard} d-flex align-items-center gap-2`} onClick={() => clickedTab !== i ? setClickedTab(i) : setClickedTab(null)} role='button'>
                            <BulletIcon active={clickedTab === i} /> <span>{item.title}</span>
                        </div>
                        {clickedTab === i && (<div className={`${classes.updateDetails} mb-3`}>
                            <div className='d-flex align-items-center justify-content-between mb-4'>
                                <h4>Today</h4><div className={classes.showType}>Show: All types</div>
                            </div>
                            {item.updateList.map((update, i) => (<div className={`${classes.updateContainer} mb-3`} key={i}>
                                <div className='d-flex align-items-center gap-3'>
                                <div className={`${classes.capsule} d-flex align-items-center gap-2`}><BoardIcon size="small"/><span>Interactive lesson</span></div><h5>{update.grade}</h5>
                                </div>
                                <div className='d-flex flex-column align-items-start mt-2'>
                                <h4>{update.desc}</h4>
                                <h5>{update.date}</h5>
                                </div>
                            </div>))}
                            <div className={`${classes.viewLessonsButton}`} role='button'>
                                View lessons
                            </div>
                        </div>)}
                    </div>))}
                </div>
    )
}

export default Accordion