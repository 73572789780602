import SideBar from "./SideBar/SideBar"
import classes from "./Layout.module.css"
import Dashboard from "../Dashboard/Dashboard"
import Lessons from "../Lessons/Lessons"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import Performance from "../Performance/Performance"
import Assessments from "../Assessments/Assessments"
import Classes from "../Classes/Classes"
import Learners from "../Learners/Learners"
import ResourceLibrary from "../ResourceLibrary/ResourceLibrary"
import Curriculum from "../Curriculum/Curriculum"
import RequestAssistance from "../RequestAssistance/RequestAssistance"
import Calendar from "../Calendar/Calendar"
import MenuIcon from "../../icons/MenuIcon"
import { useContext, useEffect, useState } from "react"
import LogoutIcon from "../../icons/LogoutIcon"
import BellIcon from "../../icons/BellIcon"
import ChatIcon from "../../icons/ChatIcon"
import LessonDetails from "../Lessons/LessonDetails/LessonDetails"
import LiveLesson from "../Lessons/LessonDetails/LiveLesson/LiveLesson"
import Grade from "../Classes/Grade/Grade"
import Learner from "../Classes/Grade/Learner/Learner"
import Resource from "../ResourceLibrary/Resource/Resource"
import HelpCategory from "../RequestAssistance/HelpCategory/HelpCategory"
import Article from "../RequestAssistance/Article/Article"
import MyAccount from "../MyAccount/MyAccount"
import ChangeInformation from "../MyAccount/ChangeInformation/ChangeInformation"
import ChangePIN from "../MyAccount/ChangePIN/ChangePIN"
import CopyrightNotice from "../MyAccount/CopyrightNotice/CopyrightNotice"
import TermsAndConditions from "../MyAccount/TermsAndConditions/TermsAndConditions"
import { useAuth } from "../../context/AuthContext"
import Cookies from 'js-cookie';
import { useLessons } from "../../context/LessonsContext"
import { useSchools } from "../../context/SchoolsContext"
import { useClasses } from "../../context/ClassesContext"

const IconContainer = ({children, onClick}) =>{
    return(
        <div className={`${classes.iconContainer} d-flex align-items-center justify-content-center`} onClick={onClick} role="button">
            {children}
        </div>
    )
}

const Layout = ({}) => {
    const [showSideBar, setShowSideBar] = useState(true)
    const {getLessons} = useLessons();
    const { handleLogout, user } = useAuth();
    const {getSchools} = useSchools();
    const {getClasses} = useClasses();

    const token = Cookies.get('authToken');


    
    let navigate = useNavigate();

    const logout = async() => {
        await handleLogout();
        navigate('/login');
      };

    useEffect(() => {
        getLessons()
        getSchools()
        getClasses()
    }, []) 

    const forcePageReload = () => {
        window.location.reload();
      };
    return(
        <div className={`${classes.wrapper} d-flex`}>
            {showSideBar && <SideBar />}
            <div className={`${classes.main} col`}>
                <div className={` d-flex align-items-center justify-content-between ${classes.header}`}>
                    <div>
                    <MenuIcon onClick={() => setShowSideBar(!showSideBar)}/>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-3">
                            <div className={classes.profileName}>
                                <h4>Welcome</h4>
                                <h5>{user.first_name} {user.last_name}</h5>
                            </div>
                       
                            <div className={classes.userImage} style={{backgroundImage: `url(/profile-placeholder.png)`}} onClick={() => navigate({pathname: `my-account`})}  role="button">

                            </div>
                        </div>
                        <IconContainer>
                            <ChatIcon />
                        </IconContainer>
                        <IconContainer>
                            <BellIcon />
                        </IconContainer>
                        <IconContainer onClick={logout}>
                            <LogoutIcon />
                        </IconContainer>
                    </div>
                </div>
                <div className={classes.content}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/lessons" element={<Lessons />} />
                        <Route path='/lessons/details/:id' element={<LessonDetails />}/>
                        <Route path='/lessons/live-lesson/:lessonId' element={<LiveLesson />}/>
                        <Route path="/calendar" element={<Calendar />} />
                        <Route path="/performance" element={<Performance />} />
                        <Route path="/assessments" element={<Assessments />} />
                        <Route path="/classes" element={<Classes />} />
                        <Route path="/classes/grade/:gradeId" element={<Grade />} />
                        <Route path="/learner/:learnerId" element={<Learner />} />
                        <Route path="/learners" element={<Learners />} />
                        <Route path="/resource-library" element={<ResourceLibrary />} />
                        <Route path="/resource-library/:resourceId" element={<Resource />} />
                        <Route path="/curriculum" element={<Curriculum />} />
                        <Route path="/request-assistance" element={<RequestAssistance />} />
                        <Route path="/request-assistance/:categoryId" element={<HelpCategory />} />
                        <Route path="/request-assistance/:categoryId/:articleId" element={<Article />} />
                        <Route path="/my-account" element={<MyAccount />} />
                        <Route path="/my-account/change-my-information" element={<ChangeInformation />} />
                        <Route path="/my-account/change-my-pin" element={<ChangePIN />} />
                        <Route path="/my-account/copyright-notice" element={<CopyrightNotice />} />
                        <Route path="/my-account/terms-and-conditions" element={<TermsAndConditions />} />
                    </Routes>
                </div>
            </div>
            
        </div>
    )
}

export default Layout