import { useState } from 'react'
import classes from './LessonDetails.module.css'
import ScheduleDetailsCard from '../../UI/ScheduleDetailsCard'
import DetailsTab from '../../UI/DetailsTab'
import FeacturesTab from '../../UI/FeacturesTab'
import ResourcesTab from '../../UI/ResourcesTab'
import LearnersTab from '../../UI/LeanersTab'
const tabs = [
   "Details","Learners", "Features", "Resources",  "Curriculum"
]

const actionButtons = [
    {
        title: "Cancel this lesson",
        color: "#FFFFFF",
        background: "#FA6D6D"
    },
    {
        title: "Cancel changes",
        color: "#262626",
        background: "#CACACA"
    },
    {
        title: "Save changes",
        color: "#FFFFFF",
        background: "#43B061"
    }
]






const LessonDetails = () => {
    const [activeTab, setActiveTab] = useState(0)

    const [schedule, setSchedule] = useState({
        title: "Mathematical Literacy Term 1",
        desc: "Live lessons / Mathematics / Teacher3 / Tuesday, 16th Feb, 2024 10:21 PM",
        id: 1
    })

    return(
        <div className={classes.main}>
            <ScheduleDetailsCard schedule={schedule} />
            <div className={`mt-4 ${classes.detailsCard}`}>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex gap-3'>
                        {tabs.map((item, i) => (<div className={classes.tabContainer} onClick={() => setActiveTab(i)} style={{background: activeTab === i && "#43B061", color: activeTab === i && "#FFFFFF"}} role='button'>
                            {item}
                        </div>))}
                    </div>
                    <div className='d-flex gap-3'>
                        {actionButtons.map((item, i) => (<div className={classes.tabContainer} style={{background: item.background, color: item.color, borderColor: item.background}} role='button'>
                            {item.title}
                        </div>))}
                    </div>
                </div>
                
                <div className={`${classes.content} mt-4 `}>
                    {activeTab === 0 && <DetailsTab />}

                    {activeTab === 1 && <LearnersTab />}

                        {activeTab === 2 && <FeacturesTab />}

                        {activeTab === 3 && <ResourcesTab />}    
                </div>
            </div>
        
        </div>
    )
}

export default LessonDetails