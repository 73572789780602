import { useState } from "react";
import CalendarComponent from "../Dashboard/Components/CalendarComponent/CalendarComponent";
import classes from "./Calendar.module.css";
import LessonDetailsModal from "../LessonDetailsModal/LessonDetailsModal";

const resourcesData = [
  {
    title: "English Grammar Term 2",
  },
  {
    title: "English Grammar Term 3",
  },
];

const Calendar = ({ lessons }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lessonDetails, setLesssonDetails] = useState({})

  const toggleLessonDetails = () => {
    console.log("OPEN DETAILS");
    setIsOpen(!isOpen);
  };
  return (
    <div className={classes.main}>
      <CalendarComponent toggleDetails={(event) => {setLesssonDetails(event); toggleLessonDetails()}}/>
      <LessonDetailsModal isOpen={isOpen} toggleDetails={toggleLessonDetails} lessonDetails={lessonDetails}/>
    </div>
  );
};

export default Calendar;
