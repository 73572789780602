import { useContext, useState } from 'react'
import classes from './Login.module.css'
import InputField from '../UI/InputField'
import BackIcon from '../../icons/BackIcon'
import { useNavigate } from 'react-router-dom'
import { env } from '../../constants/env'
import { setToken } from '../../utilities/functions'
import { useAuth } from '../../context/AuthContext'
import { Bounce, toast } from 'react-toastify'


const Login = () => {
    const [showReset, setShowReset] = useState(false)
    // const [email, setEmail] = useState("")
    // const [password, setPassword] = useState("")
    const [loginError, setLoginError] = useState("")
    const { setIsAuthenticated } = useAuth();

    let navigate = useNavigate();
    // const { fetchLessons } = useContext(LessonsContext);



    const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { handleLogin } = useAuth();
  // const history = useHistory();

  const onSubmit = async () => {
    
    try {
      await handleLogin(email, password);
      navigate('/');
    } catch (error) {
      console.error('Login failed', error);
    }
  };




    const loginHandler = async () => {
        try {
            
          const dataContent = {email, password}  
          const response = await fetch(
            `${env.directusUrl}/auth/login`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(dataContent)
            }
          );
    
          if (!response.ok) {
            const error = await response.json();
            // setLoginError(error.errors[0].message)
            if(error.errors[0].extensions.code === "TOKEN_EXPIRED"){
              toast.error(error.errors[0].message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                toastId: "session-expired"
                });

                // Cookies.remove('authToken');
            }
            throw new Error(error.errors[0]);
          }
    
          const result = await response.json();

          setToken(result.data.access_token, result.data.expires)

          console.log("SEE TOKEN", result)
          setIsAuthenticated(true);
          // Navigate to the dashboard
          navigate('/dashboard');
    
          return result;
        } catch (error) {
          console.log(error);
        }
      };
    
    return(
        <div className={`d-flex flex-column align-items-center justify-content-center ${classes.main}`}>

            <img src='/thuto-login.svg' style={{height: showReset && "80px"}}/>
            
            <div className={`d-flex flex-column mt-${showReset ? '3' : '5'} ${classes.inputWrapper}`}>
                {showReset && <>
                <div className='d-flex align-items-center gap-3 mb-5 w-50' onClick={() => setShowReset(false)} role='button'>
                    <BackIcon /><div className={classes.titleText}>Forgot my pin</div>
                </div>
                <div className={`d-flex flex-column gap-5`} >
                    <InputField label="Enter your new PIN" type="text" placeholder="Enter PIN"/>
                    <InputField label="Confirm your new PIN" type="text" placeholder="Enter PIN"/>
                </div>
                </>}
                {!showReset &&
                    <>
                    <div className={`d-flex flex-column gap-5`} >
                        <InputField label="Your Email*" type="email" placeholder="Enter your Email" onChange={(e) => { setEmail(e.target.value); setLoginError("")}}/>
                        <InputField label="Your Password*" type="password" placeholder="Enter your Password" onChange={(e) => { setPassword(e.target.value); setLoginError("")}}/>
                    </div>
                    {loginError !== "" && <div className={`${classes.errorText} mt-4`}>{loginError}</div>}
                    <div className='d-flex justify-content-end mt-4'><div className={classes.linkText} onClick={() => setShowReset(true)} role='button'>Forgot my Password</div></div>
                    </>
                }
                
                <div className={`mt-5 d-flex align-items-center justify-content-center ${classes.continueButton}`} onClick={() => !showReset ? onSubmit() : ""} role='button'>
                Continue
                </div>
            </div>
           
        </div>
    )
}

export default Login