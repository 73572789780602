import { useClasses } from "../../context/ClassesContext";
import { getOrdinal } from "../../utilities/functions";
import InputField from "./InputField";

const DetailsTab = ({
  selectedClass,
  setSelectedClass,
  lessonType,
  setLessonType,
  lessonName,
  subject,
  scheduleDate,
  startTime,
  endTime,
  setLessonName,
  setSubject,
  setScheduleDate,
  setStartTime,
  setEndTime,
}) => {
  const { schoolClasses } = useClasses();

  console.log("CLASSESSSSSSSSS", schoolClasses)
  return (
    <div className="d-flex flex-column gap-4">
      <InputField label="Lesson Type">
        <select
          value={lessonType}
          onChange={(e) => setLessonType(e.target.value)}
        >
          <option value="">--Select Lesson Type--</option>
          <option value="Interactive Lesson">Interactive Lesson</option>
          <option value="Live Lesson">Live Lesson</option>
        </select>
      </InputField>
      <InputField
        type="text"
        label="Lesson Name"
        placeholder="Field input"
        value={lessonName}
        onChange={(e) => setLessonName(e.target.value)}
      />
      <InputField
        type="text"
        label="Subject"
        placeholder="Field input"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <InputField label="School">
        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
        >
          <option value="">--Select a Class--</option>
          {schoolClasses.map((item, i) => (
            <option value={item.id} key={i}>
              {item.name} - {getOrdinal(item.grade)} grade
            </option>
          ))}
        </select>
      </InputField>
      <div className={`row`}>
        <div className="col-4">
          <InputField
            type="date"
            label="Date scheduled"
            value={scheduleDate}
            onChange={(e) => setScheduleDate(e.target.value)}
          />
        </div>
        <div class="col-4">
          <InputField
            type="time"
            label="Start time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
        <div class="col-4">
          <InputField
            type="time"
            label="End time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsTab;
