// src/services/lessons.js
import {createItem, readItems, readUser, readUsers, rest } from '@directus/sdk';
import directus from './directus';
import Cookies from 'js-cookie';

const client = directus.with(rest());

export const fetchSearchResults = async (query) => {
  try {

    // const result = await client.request(readUsers({filter: {user_role:"7c20ad12-9e9f-49af-bed5-496248de1453"} }));
 
    const result = await client.request(readUsers({filter: {_or: [{first_name: {_icontains: query}}, {last_name: {_icontains: query}}, {school_id: {_icontains: query}}  ]}}));

    console.log("SEARCH RESULTS", result)
    return result;
  } catch (error) {
    throw new Error('Failed to fetch search: ' + error.message);
  }
};


export const fetchAllLearners = async () => {
 
    const result = await client.request(readItems('learners'));

    console.log("ALLL LEARNERS RESULTS", result)

    return result;
}

export const fetchLearnersByClassId = async (classId) => {
  try {
 
    const result = await client.request(readItems('learners',{filter: {class_id: classId}}));

    console.log("LEARNERS RESULTS", result)

    return result;
  } catch (error) {
    throw new Error('Failed to fetch search: ' + error.message);
  }
};

export const fetchLearnerById = async (userId) => {
  try {
 
    const result = await client.request(readUser(userId));

    console.log("SINGLE USER ", result)
    return result;
  } catch (error) {
    throw new Error('Failed to fetch search: ' + error.message);
  }
};

export const fetchLearner = async (userId) => {
  try {
 
    const result = await client.request(readItems('learners',{filter: {user_id: userId}}));

    

    return result;
  } catch (error) {
    throw new Error('Failed to fetch search: ' + error.message);
  }
};

export const createLearner = async (learnerData) => {

    const token = Cookies.get('authToken');


    if (!token) {
      throw new Error('User not authenticated');
    }

    const result = await client.request(createItem('learners', learnerData,  {access_token: token}));
}