
import InfoDetailsCard from '../../UI/InfoDetailsCard'
import SearchBar from '../../UI/SearchBar'
import classes from './Article.module.css'


const Article= () => {

    return(
        <div className={classes.main}>
            <SearchBar placeholder="Search article" className="mb-4"/>
            <InfoDetailsCard title="Article name"/>
        </div>
    )
}

export default Article