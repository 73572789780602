import classes from './Table.module.css'

const tableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Class",
        width: '100px'
    },
    {
        title: "Avg. Grade",
        width: '100px'
    },
    {
        title: "Last grade",
        width: '100px'
    },
    {
        title: "Test assigned",
        width: '150px'
    },
    {
        title: "Actions",
        width: '100px'
    } 
]

const tableContent = [
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "B +",
        testAssigned: "Yes"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "B +",
        testAssigned: "Yes"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "B +",
        testAssigned: "Yes"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "B +",
        testAssigned: "Yes"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "B +",
        testAssigned: "Yes"
    }
]


const LearnersTab = () => {
    return(
        <table className={`w-100 ${classes.learnerTable}`}>
            <tr>
            {tableHeader.map((item, i) => (
                <th style={{width: item.width}} key={i}>{item.title}</th>
            ))}
            </tr>
            {tableContent.map((item, i) => (<tr>
                <td>{item.name}</td>
                <td>{item.class}</td>
                <td>{item.avgGrade}</td>
                <td>{item.lastGrade}</td>
                <td>{item.testAssigned}</td>
                <td><div className={classes.removeButton} role='button'>Remove</div></td>
            </tr>))}
        </table>
    )
}

export default LearnersTab