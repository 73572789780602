
import CameraIcon from '../../icons/CameraIcon';
import classes from './MyAccount.module.css'
import { useNavigate } from 'react-router-dom'

const infoList = [
    {
        title: "Change my information",
        link: "change-my-information"
    },
    {
        title: "Change my PIN",
        link: "change-my-pin"
    },
    {
        title: "Copyright notice",
        link: "copyright-notice"
    },
    {
        title: "Terms and conditions",
        link: "terms-and-conditions"
    },
    {
        title: "Close account",
        link: ""
    },
    {
        title: "Log out",
        link: ""
    }
]



const MyAccount = () => {

    let navigate = useNavigate();

    return(
        <div className={classes.main}>
            <div className={`${classes.profileWrapper} mb-4`}>
                <div className={classes.userImage} style={{backgroundImage: `url(/teacher-2.png)`}}>
                    <div className={`${classes.iconContainer} d-flex align-items-center justify-content-center`}>
                        <CameraIcon />
                    </div>
                </div>
                <h3>Joseph Myers (ID: 2981882)</h3>
                <h5>Teacher, District, Circuit</h5> 
            </div>
            <div className='d-flex flex-column gap-3'>
                {infoList.map((item, i) => <div className={`d-flex align-items-center justify-content-start ${classes.listCard}`} onClick={() => navigate({pathname: `${item.link}`})} key={i} role='button'>{item.title}</div>)}
            </div>
        </div>
    )
}

export default MyAccount