import classes from "./Dashboard.module.css";
import Accordion from "../UI/Accordion";
import InfoCard from "../UI/InfoCard";
import CalendarComponent from "./Components/CalendarComponent/CalendarComponent";
import { useState } from "react";
import LessonDetailsModal from "../LessonDetailsModal/LessonDetailsModal";

const resourcesData = [
  {
    title: "English Grammar Term 2",
  },
  {
    title: "English Grammar Term 3",
  },
];

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [lessonDetails, setLesssonDetails] = useState({});

  const toggleLessonDetails = () => {
    setIsOpen(!isOpen);
  };
  const cards = [
    {
      title: "Average grade:",
      grade: "B+",
    },
    {
      title: "Attendance:",
      grade: "98%",
    },
  ];

  const dropList = [
    {
      title: "Heading",
      updateList: [
        {
          grade: "9th Grade",
          desc: "NS & Tech: Matter and Materials",
          date: "Tuesday 16 Feb - 3PM - 4PM",
        },
        {
          grade: "9th Grade",
          desc: "NS & Tech: Matter and Materials",
          date: "Tuesday 16 Feb - 3PM - 4PM",
        },
      ],
    },
    {
      title: "Heading",
      updateList: [
        {
          grade: "9th Grade",
          desc: "NS & Tech: Matter and Materials",
          date: "Tuesday 16 Feb - 3PM - 4PM",
        },
        {
          grade: "9th Grade",
          desc: "NS & Tech: Matter and Materials",
          date: "Tuesday 16 Feb - 3PM - 4PM",
        },
      ],
    },
    {
      title: "Events",
      updateList: [],
    },
    {
      title: "Students",
      updateList: [],
    },
    {
      title: "Grade schedule",
      updateList: [],
    },
    {
      title: "Analytics",
      updateList: [],
    },
    {
      title: "Resources",
      updateList: [],
    },
    {
      title: "Curriculum",
      updateList: [],
    },
  ];

  return (
    <div className={`row ${classes.main}`}>
      <div className={`col-6 ${classes.calendarWrapper}`}>
        <CalendarComponent
          dashoard={true}
          toggleDetails={(event) => {
            setLesssonDetails(event);
            toggleLessonDetails();
          }}
        />
      </div>
      <div className={`col-6 ${classes.updateWrapper}`}>
        <div className="row m-0 gap-3 w-100 ">
          {cards.map((item, i) => (
            <InfoCard
              title={item.title}
              grade={item.grade}
              buttonText="View details"
            />
          ))}
        </div>
        <Accordion dropList={dropList} />
      </div>
      <LessonDetailsModal isOpen={isOpen} toggleDetails={toggleLessonDetails} lessonDetails={lessonDetails}/>
    </div>
  );
};

export default Dashboard;
