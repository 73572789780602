import SearchIcon from '../../icons/SearchIcon'
import classes from './SearchBar.module.css'

const SearchBar = ({className, placeholder, value, onChange}) => {
    return(
        <div className={`${classes.inputWrapper} ${className} d-flex align-items-center`}>
            <input type="text" placeholder={placeholder} value={value} onChange={onChange}/><SearchIcon />
        </div>
    )
}

export default SearchBar