import SolidStarIcon from '../../icons/SolidStarIcon'
import { calcDuration, formatedDateTime } from '../../utilities/functions'
import CalendarLessonDetails from '../CalendarLessonDetails/CalendarLessonDetails'
import AboutLessonCard from '../UI/AboutLessonCard/AboutLessonCard'
import CardContainer from '../UI/CardContainer/CardContainer'
import SectionTitleContainer from '../UI/SectionTitleContainer/SectionTitleContainer'
import classes from './LessonDetailsModal.module.css'

const LessonDetailsModal = ({isOpen, toggleDetails, lessonDetails}) => {
    return(
        <CalendarLessonDetails
        isOpen={isOpen}
        toggleDetails={toggleDetails}
        lessonName={lessonDetails.lessonName} lessonType={lessonDetails.lessonsType} subject={lessonDetails.title} teacher={lessonDetails.createdBy}
      >
        <div className={classes.lessonDetailsWrapper}>
          <SectionTitleContainer heading="About this lesson" className="mt-4" />
          <div className="row">
            <AboutLessonCard title="Type" value={(lessonDetails.lessonsType === "Interactive Lesson") ? "Interactive" : "Live"}/>
            <AboutLessonCard title="Duration" value={calcDuration(lessonDetails.start, lessonDetails.end)}/>
            <AboutLessonCard title="Scheduled" value={formatedDateTime(lessonDetails.start)}/>
          </div>

          <SectionTitleContainer
            heading="Resources for this lesson"
            className="mt-4"
            sort="Show: All types"
          />
          {/* {resourcesData.map((item, i) => (
            <CardContainer
              className={`d-flex align-items-center justify-content-between mb-3 ${classes.resourceCard}`}
            >
              <div>
                <div className={`mb-2 ${classes.resourcePill}`}>Resource</div>
                <h4>{item.title}</h4>
              </div>
              <SolidStarIcon />
            </CardContainer>
          ))} */}
          <CardContainer
            className={`d-flex align-items-center justify-content-between mb-3 ${classes.resourceCard}`}
          >
            <h4 style={{ color: "#959595" }}>
              You have no resources for this lesson
            </h4>
          </CardContainer>

          <SectionTitleContainer
            heading="Activity for this lesson"
            className="mt-4"
            sort="Show: All types"
          />
          <CardContainer
            className={`d-flex align-items-center justify-content-between mb-3 ${classes.resourceCard}`}
          >
            <h4 style={{ color: "#959595" }}>
              You have no activity for this lesson
            </h4>
          </CardContainer>
        </div>
      </CalendarLessonDetails>
    )
}

export default LessonDetailsModal