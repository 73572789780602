import SearchBar from "../UI/SearchBar";
import tabeleClasses from "../UI/Table.module.css";
import SortItems from "../UI/SortItems";
import classes from "./Learners.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchAllLearners } from "../../services/learners";
import { fetchClassesById } from "../../services/classes";
import { getOrdinal } from "../../utilities/functions";

const tableHeader = [
  {
    title: "Name",
    width: "auto",
  },
  {
    title: "Class",
    width: "100px",
  },
  {
    title: "Avg. Grade",
    width: "100px",
  },
  {
    title: "Last grade",
    width: "150px",
  },
  {
    title: "Best grade",
    width: "150px",
  },
  {
    title: "Best performing",
    width: "150px",
  },
  {
    title: "Worst performing",
    width: "150px",
  },
  {
    title: "Actions",
    width: "100px",
  },
];

const tableContent = [
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 1,
  },
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 2,
  },
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 3,
  },
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 4,
  },
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 5,
  },
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 6,
  },
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 7,
  },
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 8,
  },
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 9,
  },
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 10,
  },
  {
    name: "Learner name",
    class: "9th Grade",
    avgGrade: "B+",
    lastGrade: "C+",
    bestGrade: "A",
    bestPerforming: "Mathematics",
    worstPerforming: "Technology",
    id: 11,
  },
];

const Learners = () => {
  let navigate = useNavigate();

  const [learners, setLearners] = useState([]);


  const getClassLearners = async () => {
    try {
      const response = await fetchAllLearners();

      const groupedData = response.map(async (item) => {
        const classDetails = await fetchClassesById(item.class_id);
        return {
          name: `${item.first_name} ${item.last_name}`,
          id: item.user_id,
          grade: getOrdinal(+classDetails.grade) + " " + "Grade",
        };
      });

      const results = await Promise.all(groupedData);

      setLearners(results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getClassLearners();
  }, []);

  return (
    <div className={classes.main}>
      <SearchBar placeholder="Search learners" />
      <div className="mt-4">
        <SortItems heading="Learners" />
        <table className={`w-100 mt-3 ${tabeleClasses.learnerTable}`}>
          <tr>
            {tableHeader.map((item, i) => (
              <th style={{ width: item.width }} key={i}>
                {item.title}
              </th>
            ))}
          </tr>
          {learners.map((item, i) => (
            <tr>
              <td>
                <div className={tabeleClasses.removeButton} role="button" onClick={() => navigate({ pathname:`/learner/${item.id}`})}>
                  {item.name}
                </div>
              </td>
              <td>{item.grade}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <div className={tabeleClasses.removeButton} role="button">
                  ASSIGN TEST
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};

export default Learners;
