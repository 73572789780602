import { useNavigate, useParams } from "react-router-dom";
import SearchBar from "../../UI/SearchBar";
import SortItems from "../../UI/SortItems";
import tabeleClasses from "../../UI/Table.module.css";
import classes from "./Grade.module.css";
import { useEffect, useState } from "react";
import Overlay from "../../UI/Overlay";
import ModalCard from "../../UI/ModalCard/ModalCard";
import {
  createLearner,
  fetchLearnersByClassId,
  fetchSearchResults,
} from "../../../services/learners";
import { Bounce, toast } from "react-toastify";
import { updateClass } from "../../../services/classes";
import { IoCloseOutline } from "react-icons/io5";

const tableHeader = [
  {
    title: "Name",
    width: "auto",
  },
  // {
  //     title: "Class",
  //     width: '100px'
  // },
  // {
  //     title: "Grade",
  //     width: '100px'
  // },
  {
    title: "Tests Assigned",
    width: "150px",
  },
  {
    title: "Tests finished",
    width: "150px",
  },
  {
    title: "Actions",
    width: "100px",
  },
];

const tableContent = [
  {
    name: "Learner name",
    class: "9th Grade A",
    grade: "B+",
    testsAssigned: "B+",
    testsFinished: "B+",
    id: 1,
  },
  {
    name: "Learner name",
    class: "9th Grade A",
    grade: "B+",
    testsAssigned: "B+",
    testsFinished: "B+",
    id: 2,
  },
  {
    name: "Learner name",
    class: "9th Grade A",
    grade: "B+",
    testsAssigned: "B+",
    testsFinished: "B+",
    id: 3,
  },
  {
    name: "Learner name",
    class: "9th Grade A",
    grade: "B+",
    testsAssigned: "B+",
    testsFinished: "B+",
    id: 4,
  },
  {
    name: "Learner name",
    class: "9th Grade A",
    grade: "B+",
    testsAssigned: "B+",
    testsFinished: "B+",
    id: 5,
  },
  {
    name: "Learner name",
    class: "9th Grade A",
    grade: "B+",
    testsAssigned: "B+",
    testsFinished: "B+",
    id: 6,
  },
  {
    name: "Learner name",
    class: "9th Grade A",
    grade: "B+",
    testsAssigned: "B+",
    testsFinished: "B+",
    id: 7,
  },
  {
    name: "Learner name",
    class: "9th Grade A",
    grade: "B+",
    testsAssigned: "B+",
    testsFinished: "B+",
    id: 8,
  },
];

const Grade = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addedLearners, setAddedLearners] = useState([]);
  const [learners, setLearners] = useState([]);

  const { gradeId } = useParams();

  console.log("results Learners", learners);

  let navigate = useNavigate();

  useEffect(() => {
    getClassLearners();
  }, [gradeId]);

  const getClassLearners = async () => {
    try {
      const response = await fetchLearnersByClassId(gradeId);
      setAddedLearners(response);
      setLearners(response);
    } catch (err) {}
  };

  const searchHandler = async (e) => {
    setSearch(e.target.value);

    if (e.target.value.length > 0) {
      setShowSearchResults(true);
      const response = await fetchSearchResults(e.target.value);
      setSearchResults(response);
    } else {
      setShowSearchResults(false);
    }
  };

  const closeFilterHandler = () => {
    setSearch("");
    setShowSearchResults(false);
  };

  const addHandler = async () => {
    try {
        setIsLoading(true)
      await createLearner(addedLearners);

      toast.success("Class updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        toastId: "session-expired",
      });
    } catch (error) {
      console.log("Error", error);
      toast.error("Error", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        toastId: "session-expired",
      });
    }
    setIsLoading(false)
    setShowOverlay(false);
  };

  return (
    <div className={classes.main}>
      <SearchBar />
      <div className="d-flex align-items-center justify-content-between">
        <div></div>
        <button onClick={() => {getClassLearners(); setShowOverlay(true)}} style={{ width: 200 }}>
          Add Learner
        </button>
      </div>
      <div className="mt-4">
        <SortItems heading="Learners in class" />
        <table className={`w-100 mt-3 ${tabeleClasses.learnerTable}`}>
          <tr>
            {tableHeader.map((item, i) => (
              <th style={{ width: item.width }} key={i}>
                {item.title}
              </th>
            ))}
          </tr>
          {learners.map((item, i) => (
            <tr>
              <td>
                <div
                  className={tabeleClasses.removeButton}
                  onClick={() =>
                    navigate({ pathname: `/learner/${item.user_id}` })
                  }
                  role="button"
                >
                  {item.first_name} {item.last_name}
                </div>
              </td>
              {/* <td>{item.class}</td>
                    <td>{item.grade}</td> */}
              <td>{item.testsAssigned}</td>
              <td>{item.testsFinished}</td>
              <td>
                <div className={tabeleClasses.removeButton} role="button">
                  ASSIGN TEST
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>

      {showOverlay && (
        <Overlay onClick={() => setShowOverlay(false)}>
          <ModalCard
            title="Add new Leaner"
            onClose={() => setShowOverlay(false)}
          >
            <div className="d-flex flex-column justify-content-center gap-4 mt-5">
              <div className={classes.searchWrapper}>
                <SearchBar value={search} onChange={searchHandler} />
                {showSearchResults && (
                  <div className={classes.filterCard}>
                    <div>
                      <div className="d-flex justify-content-end">
                        <IoCloseOutline
                          size={24}
                          onClick={closeFilterHandler}
                          role="button"
                        />
                      </div>
                    </div>
                    {searchResults.map((item, i) => (
                      <div
                        className={`${classes.checkboxContainer} d-flex align-items-center`}
                      >
                        <input
                          className={classes.checkInput}
                          type="checkbox"
                          name="overview"
                          value={item.id}
                          checked={addedLearners.some(
                            (learner) => learner.user_id === item.id
                          )}
                          onChange={(e) => {
                            if (
                              !addedLearners.some(
                                (learner) => learner.user_id === item.id
                              )
                            ) {
                              setAddedLearners([
                                ...addedLearners,
                                {
                                  class_id: gradeId,
                                  user_id: item.id,
                                  first_name: item.first_name,
                                  last_name: item.last_name,
                                },
                              ]);
                            } else {
                              setAddedLearners((current) =>
                                current.filter(
                                  (access) => access.user_id !== item.id
                                )
                              );
                            }
                          }}
                        />
                        <div
                          className={`${classes.radioLabel} ms-2`}
                        >{`${item.first_name} ${item.last_name} - ${item.school_id}`}</div>
                      </div>
                    ))}
                    {/* <button onClick={addHandler}>Add Learners</button> */}
                  </div>
                )}
              </div>
              <div className="row gap-3 mx-0">
                {addedLearners.map((item, i) => (
                  <div
                    className={`${classes.addPillCard} d-flex align-items-center gap-1`}
                    key={i}
                  >
                    <IoCloseOutline
                      size={20}
                      role="button"
                      onClick={() =>
                        setAddedLearners((current) =>
                          current.filter(
                            (access) => access.user_id !== item.user_id
                          )
                        )
                      }
                    />{" "}
                    {item.first_name} {item.last_name}
                  </div>
                ))}
              </div>
              {isLoading ?  <div className="d-flex justify-content-center"><div className="spinner-border" role="status"></div></div> : <button onClick={addHandler}>Submit</button>}
            </div>
          </ModalCard>
        </Overlay>
      )}
    </div>
  );
};

export default Grade;
