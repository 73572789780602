import classes from './VerticalTabs.module.css'

const VerticalTabs = ({tabs, activeTab, setActiveTab, className}) => {
    return(
        <div className='d-flex gap-3'>
            {tabs.map((item, i) => (<div className={`${className} ${classes.tabContainer}`} onClick={() => setActiveTab(i)} style={{background: activeTab === i && "#43B061", color: activeTab === i && "#FFFFFF"}} role='button'>
                {item}
            </div>))}
        </div>
    )
}

export default VerticalTabs