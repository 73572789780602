import { useState } from 'react'
import InfoCard from '../UI/InfoCard'
import SearchBar from '../UI/SearchBar'
import VerticalTabs from '../UI/VerticalTabs'
import classes from '../Performance/Performance.module.css'
import SortItems from '../UI/SortItems'

const infoList = [
    {
        title: "Average grade",
        buttonText: "A"
    },
    {
        title: "Best attendance",
        buttonText: "10th grade C"
    },
    {
        title: "Best performing class",
        buttonText: "9th grade A"
    },
    {
        title: "Worst performing class",
        buttonText: "9th grade C"
    }
]

const tableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Class",
        width: '100px'
    },
    {
        title: "Grade",
        width: '100px'
    },
    {
        title: "Actions",
        width: '100px'
    } 
]

const tableContent = [
    {
        name: "Learner name",
        class: "9th Grade",
        grade: "B +"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        grade: "B +"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        grade: "B +"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        grade: "B +"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        grade: "B +"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        grade: "B +"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        grade: "B +"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        grade: "B +"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        grade: "B +"
    }
]


const attendanceTableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Class",
        width: '100px'
    },
    {
        title: "Actions",
        width: '100px'
    } 
]

const attendanceTableContent = [
    {
        name: "Learner name",
        class: "9th Grade",
        unseen: true
    },
    {
        name: "Learner name",
        class: "9th Grade",
        unseen: true
    },
    {
        name: "Learner name",
        class: "9th Grade",
        unseen: true
    },
    {
        name: "Learner name",
        class: "9th Grade",
        unseen: true
    },
    {
        name: "Learner name",
        class: "9th Grade",
        unseen: true
    },
    {
        name: "Learner name",
        class: "9th Grade",
        unseen: false
    },
    {
        name: "Learner name",
        class: "9th Grade",
        unseen: false
    },
    {
        name: "Learner name",
        class: "9th Grade",
        unseen: false
    },
    {
        name: "Learner name",
        class: "9th Grade",
        unseen: false
    }
]

const tabs = [
    "Assessments graded","Assessments not graded"
 ]

const Assessments = () => {
    const [activeTab, setActiveTab] = useState(0)

    return(
        <div className={classes.main}>
            <SearchBar placeholder="Search learners" />
            <div className='row mt-4'>
                {infoList.map((item, i) => (<div className='col-3'key={i} >
                    <InfoCard title={item.title} subText={item.buttonText} />
                </div>))}
            </div>
            <VerticalTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} className='mt-4'/>
            <div className='mt-4'>
            {activeTab === 0 && <><SortItems heading="Assessments"/>
           <table className={`w-100 mt-3 ${classes.learnerTable}`}>
                        <tr>
                        {tableHeader.map((item, i) => (
                            <th style={{width: item.width}} key={i}>{item.title}</th>
                        ))}
                        </tr>
                        {tableContent.map((item, i) => (<tr>
                            <td><div className={classes.removeButton} role='button'>{item.name}</div></td>
                            <td>{item.class}</td>
                            <td>{item.grade}</td>
                            <td><div className={classes.removeButton} role='button'>View test</div></td>
                        </tr>))}
                        </table></>}
            {activeTab === 1 && <><SortItems heading="Assessments"/>
            <table className={`w-100 mt-3 ${classes.learnerTable}`}>
                        <tr>
                        {attendanceTableHeader.map((item, i) => (
                            <th style={{width: item.width}} key={i}>{item.title}</th>
                        ))}
                        </tr>
                        {attendanceTableContent.map((item, i) => (<tr>
                            <td><div className={`d-flex align-items-center ${classes.removeButton}`} role='button' style={{fontWeight: item.unseen && 'bold'}}>{item.unseen && <div className={classes.notificationDot}></div>}{item.name}</div></td>
                            <td style={{fontWeight: item.unseen && 'bold'}}>{item.class}</td>
                            <td><div className={classes.removeButton} role='button' style={{fontWeight: item.unseen && 'bold'}}>View test</div></td>
                        </tr>))}
                        </table></>}        
            </div>
        </div>
    )
}

export default Assessments