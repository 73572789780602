import { useEffect, useState } from "react"
import Accordion from "../../../UI/Accordion"
import SearchBar from "../../../UI/SearchBar"
import tabeleClasses from '../../../UI/Table.module.css'
import VerticalTabs from "../../../UI/VerticalTabs"
import classes from "./Learner.module.css"
import { useParams } from "react-router-dom"
import { fetchLearner, fetchLearnerById } from "../../../../services/learners"
import { fetchClassesById } from "../../../../services/classes"
import { fetchLessonsByClass } from "../../../../services/lessons"

const cards = [
    {
        title: "Average grade:",
        number: "B+",
    },
    {
        title: "Attendance: ",
        number: "98%",
    }
]

const dropList = [
    {
        title: "Heading",
        updateList: [
            {
                grade: "9th Grade",
                desc: "NS & Tech: Matter and Materials",
                date: "Tuesday 16 Feb - 3PM - 4PM"
            },
            {
                grade: "9th Grade",
                desc: "NS & Tech: Matter and Materials",
                date: "Tuesday 16 Feb - 3PM - 4PM"
            }
    ]
    },
    {
        title: "Grade schedule",
        updateList: [
            {
                grade: "9th Grade",
                desc: "NS & Tech: Matter and Materials",
                date: "Tuesday 16 Feb - 3PM - 4PM"
            },
            {
                grade: "9th Grade",
                desc: "NS & Tech: Matter and Materials",
                date: "Tuesday 16 Feb - 3PM - 4PM"
            }
        ]
    },
    {
        title: "Curriculum",
        updateList: [
           
        ]
    }
]

const tableHeader = [
    {
        title: "Lesson name",
        width: 'auto'
    },
    {
        title: "Lesson type",
        width: 'auto'
    },
    {
        title: "Subject",
        width: 'auto'
    },
    {
        title: "Date",
        width: '170px'
    },
    {
        title: "Actions",
        width: '100px'
    } 
]

const tableContent = [
    {
        lessonType: "Learner name",
        grade: "C+",
        date: "6th Feb, 2024 10:21 AM",
        id: 1
    },
    {
        lessonType: "Learner name",
        grade: "C+",
        date: "6th Feb, 2024 10:21 AM",
        id: 2
    },
    {
        lessonType: "Learner name",
        grade: "C+",
        date: "6th Feb, 2024 10:21 AM",
        id: 3
    },
    {
        lessonType: "Learner name",
        grade: "C+",
        date: "6th Feb, 2024 10:21 AM",
        id: 4
    },
    {
        lessonType: "Learner name",
        grade: "C+",
        date: "6th Feb, 2024 10:21 AM",
        id: 5
    },
    {
        lessonType: "Learner name",
        grade: "C+",
        date: "6th Feb, 2024 10:21 AM",
        id: 6
    },
    {
        lessonType: "Learner name",
        grade: "C+",
        date: "6th Feb, 2024 10:21 AM",
        id: 7
    },
    {
        lessonType: "Learner name",
        grade: "C+",
        date: "6th Feb, 2024 10:21 AM",
        id: 8
    },
    {
        lessonType: "Learner name",
        grade: "C+",
        date: "6th Feb, 2024 10:21 AM",
        id: 9
    },
    {
        lessonType: "Learner name",
        grade: "C+",
        date: "6th Feb, 2024 10:21 AM",
        id: 10
    }
]

const attendanceTableHeader = [
    {
        title: "Date",
        width: 'auto'
    },
    {
        title: "Lesson",
        width: 'auto'
    },
    {
        title: "Attended",
       width: 'auto'
    }
]

const attendanceTableContent = [
    {
        date: "6th Feb, 2024 10:21 AM",
        lesson: "Mathematics",
        attended: "No",
        id: 1
    },
    {
        date: "6th Feb, 2024 10:21 AM",
        lesson: "Mathematics",
        attended: "No",
        id: 2
    },
    {
        date: "6th Feb, 2024 10:21 AM",
        lesson: "Mathematics",
        attended: "No",
        id: 3
    },
    {
        date: "6th Feb, 2024 10:21 AM",
        lesson: "Mathematics",
        attended: "No",
        id: 4
    },
    {
        date: "6th Feb, 2024 10:21 AM",
        lesson: "Mathematics",
        attended: "No",
        id: 5
    },
    {
        date: "6th Feb, 2024 10:21 AM",
        lesson: "Mathematics",
        attended: "No",
        id: 6
    },
    {
        date: "6th Feb, 2024 10:21 AM",
        lesson: "Mathematics",
        attended: "No",
        id: 7
    },
    {
        date: "6th Feb, 2024 10:21 AM",
        lesson: "Mathematics",
        attended: "No",
        id: 8
    }
]

const tabs = [
    "Grade performance", "Attendance"
]

const Learner = () => {
    const [activeTab, setActiveTab] = useState(0)
    const [userDetails, setUserDeatils] = useState({})
    const [classDetails, setClassDetails] = useState({})
    const [lessonsDetails, setLessonsDetails] = useState([])

    const { learnerId } = useParams();

    console.log("results", learnerId)

    useEffect(() => {
        const getUserDetails = async () => {
            try{
               const response =  await fetchLearnerById(learnerId)
               setUserDeatils(response)
               const learnerRes =  await fetchLearner(response.id)

               const classRes =  await fetchClassesById(learnerRes[0].class_id); 

               const lessonsRes = await fetchLessonsByClass(learnerRes[0].class_id)

               console.log("LESSONS BY CLASS", lessonsRes)
 
               setClassDetails(classRes)
               setLessonsDetails(lessonsRes)


            }catch(err){
                console.log(err)
            }
           
        }

        getUserDetails()
    },[learnerId])

    return(
        <div className='row'>
            <div className={`col-6 ${classes.tableWrapper}`}>
                <VerticalTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                <SearchBar className="mt-4" />
                <div className='mt-4'>
                    {activeTab === 0 && <table className={`w-100 mt-3 ${tabeleClasses.learnerTable}`}>
                        <tr>
                        {tableHeader.map((item, i) => (
                            <th style={{width: item.width}} key={i}>{item.title}</th>
                        ))}
                        </tr>
                        {lessonsDetails.map((item, i) => (<tr>
                            <td>{item.lesson_name}</td>
                            <td>{item.lesson_type}</td>
                            <td>{item.Subject}</td>
                            <td>{item.date_scheduled}</td>
                            {/* <td><div className={tabeleClasses.removeButton} role='button'>CHANGE</div></td> */}
                            <td><div className={tabeleClasses.removeButton} role='button'>VIEW</div></td>
                        </tr>))}
                    </table>}

                    {activeTab === 1 && <table className={`w-100 mt-3 ${tabeleClasses.learnerTable}`}>
                        <tr>
                        {attendanceTableHeader.map((item, i) => (
                            <th style={{width: item.width}} key={i}>{item.title}</th>
                        ))}
                        </tr>
                        {attendanceTableContent.map((item, i) => (<tr>
                            <td>{item.date}</td>
                            <td>{item.lesson}</td>
                            <td>{item.attended}</td>
                        </tr>))}
                    </table>}
                </div>
            </div>
            <div className={`col-6 ${classes.updateWrapper}`}>
                <div className=" d-flex flex-column align-items-center mb-4">
                    <div className={classes.userImage} style={{backgroundImage: `url(/learner.png)`}}>
                    </div>
                    <h3>{userDetails.first_name} {userDetails.last_name} (ID: {userDetails.school_id})</h3>
                    <h5>Grade: {classDetails.grade} , {classDetails.school?.name}, {classDetails.school?.district}, {classDetails.school?.circuit}</h5> 
                </div>
                <div className='row m-0 gap-3 w-100 '>
                    {cards.map((item, i) => (<div className={`col d-flex justify-content-between flex-column  ${classes.cardCatainer}`} key={i}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>{item.title}</div>
                            <div>{item.number}</div>
                        </div>
                    </div>))}
                </div>
                <Accordion dropList={dropList}/>
            </div>
        </div>
    )
}

export default Learner