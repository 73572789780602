import React from 'react'
import './switcher.style.scss'

const Switcher = ({ value, onChange }) => {
  const change = () => {
    onChange(!value)
  }

  return (
    <div
      id='switcher'
      className={`${value ? 'switcher active' : 'switcher'} d-flex align-items-center`}
      onClick={change}
      role='button'
    >
     <span className='activeText'>{value ? 'Active' : 'Inactive'}</span> 
      <div className='handle'></div>
    </div>
  )
}

export default Switcher
