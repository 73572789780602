import classes from './Table.module.css'

const resourcesHeader = [
    {
        title: "Resource name",
        width: 'auto'
    },
    {
        title: "Type",
        width: '150px'
    },
    {
        title: "Category",
        width: '150px'
    },
    {
        title: "Actions",
        width: '150px'
    }
]

const resourcesTableContent = [
    {
        name: "Resource name",
        type: "Resource",
        Category: "Mathematics",
    },
    {
        name: "Resource name",
        type: "Grades",
        Category: "Mathematics",
    },
    {
        name: "Resource name",
        type: "Assessment",
        Category: "Mathematics",
    },
    {
        name: "Resource name",
        type: "Resource",
        Category: "Mathematics",
    },
    {
        name: "Resource name",
        type: "Resource",
        Category: "Mathematics",
    }
]

const ResourcesTab = () => {
    return(
        <table className={`w-100 ${classes.learnerTable}`}>
            <tr>
            {resourcesHeader.map((item, i) => (
                <th style={{width: item.width}} key={i}>{item.title}</th>
            ))}
            </tr>
            {resourcesTableContent.map((item, i) => (<tr>
                <td>{item.name}</td>
                <td><div className={classes.capsule} style={{background: item.type === "Grades" ? "#DFF0FF" :  item.type === "Assessment" && "#F8C4CD", color: item.type === "Grades" ? "#76A3CC" :  item.type === "Assessment" && "#C36C7B"}}>{item.type}</div></td>
                <td>{item.Category}</td>
                <td><div className={classes.removeButton} role='button'>Remove</div></td>
            </tr>))}
        </table>
    )
}

export default ResourcesTab