// src/services/lessons.js
import { createItem, readItems, rest } from '@directus/sdk';
import directus from './directus';
import Cookies from 'js-cookie';

const client = directus.with(rest());

export const fetchCurriculum = async () => {
  try {
    const token = Cookies.get('authToken');

    if (!token) {
      throw new Error('User not authenticated');
    }

    const lessons = await client.request(readItems('curriculum', {fields: ["*.*"]}, {access_token: token} ));

    console.log("Curriculum", lessons)
    return lessons;
  } catch (error) {
    throw new Error('Failed to fetch curriculum: ' + error.message);
  }
};