import classes from './ChangePIN.module.css'
import InputField from '../../UI/InputField'




const ChangePIN= () => {

    return(
        <div className={classes.main}>
            <div className={classes.cardContainer}>
                <div className='d-flex flex-column gap-4'>
                    <InputField type="text" label="Email*" placeholder="Field input" />
                    <InputField type="text" label="PIN*" placeholder="Field input" />
                    <InputField type="text" label="Enter new PIN" placeholder="Field input" />
                    <InputField type="text" label="Confirm PIN" placeholder="Field input" />
                    <div className={classes.saveButton} role='button'>Save changes</div>
                    <div className={classes.cancelButton} role='button'>Cancel</div>
                </div>
            </div>
        </div>
    )
}

export default ChangePIN