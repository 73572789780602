// src/context/LessonsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchSchools } from '../services/schools';



const SchoolsContext = createContext();

export const SchoolsProvider = ({ children }) => {
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
    

  //   getLessons();
  // }, []);

  

  const getSchools = async () => {
    try {
      const schoolsData = await fetchSchools();

      console.log("SCHOOLS IN CONTEXT", schoolsData)
      setSchools(schoolsData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SchoolsContext.Provider value={{ schools, loading, getSchools, error }}>
      {children}
    </SchoolsContext.Provider>
  );
};

export const useSchools = () => useContext(SchoolsContext);