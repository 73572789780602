import { useState } from 'react'
import SearchBar from '../../../UI/SearchBar'
import VerticalTabs from '../../../UI/VerticalTabs'
import classes from './LiveLesson.module.css'

const tabs = [
    "Learners","Grades", "Chat"
 ]

 const tableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Test assigned",
        width: '120px'
    },
    {
        title: "Actions",
        width: '90px'
    } 
]

const tableContent = [
    {
        name: "Learner name",
        testAssigned: "View Results",
    },
    {
        name: "Learner name",
        testAssigned: "View Results",
    },
    {
        name: "Learner name",
        testAssigned: "View Results",
    },
    {
        name: "Learner name",
        testAssigned: "View Results",
    },
    {
        name: "Learner name",
        testAssigned: "View Results",
    },
    {
        name: "Learner name",
        testAssigned: "View Results",
    },
    {
        name: "Learner name",
        testAssigned: "Assign",
    },
    {
        name: "Learner name",
        testAssigned: "Assign",
    },
]

const gradeTableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Grade",
        width: '120px'
    },
    {
        title: "Actions",
        width: '90px'
    } 
]

const gradeTableContent = [
    {
        name: "Learner name",
        grade: "Assigned test",
        assigned: true
    },
    {
        name: "Learner name",
        grade: "Assigned test",
        assigned: true
    },
    {
        name: "Learner name",
        grade: "Assigned test",
        assigned: true
    },
    {
        name: "Learner name",
        grade: "A+",
        assigned: false
    },
    {
        name: "Learner name",
        grade: "View Results",
        assigned: false
    },
    {
        name: "Learner name",
        grade: "View Results",
        assigned: false
    },
    {
        name: "Learner name",
        grade: "A+",
        assigned: false
    },
    {
        name: "Learner name",
        grade: "A+",
        assigned: false
    },
    {
        name: "Learner name",
        grade: "A+",
        assigned: false
    },
    {
        name: "Learner name",
        grade: "Assigned test",
        assigned: true
    },
    {
        name: "Learner name",
        grade: "Assigned test",
        assigned: true
    }
]

const LiveLesson = () => {
    const [activeTab, setActiveTab] = useState(0)
    return(
        <div className={`${classes.main} row`}>
            <div className={`col-8 ${classes.liveStreamWrapper}`}>
                <div className={classes.liveStreamContainer}>

                </div>

            </div>
            <div className={`col-4 ${classes.updateWrapper}`}>
                <VerticalTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} className="col"/>
                <SearchBar className='mt-4'/>
                {activeTab === 0 && 
                <table className={`w-100 mt-3 ${classes.learnerTable}`}>
                    <tr>
                    {tableHeader.map((item, i) => (
                        <th style={{width: item.width}} key={i}>{item.title}</th>
                    ))}
                    </tr>
                    {tableContent.map((item, i) => (<tr>
                        <td>{item.name}</td>
                        <td><div className={classes.removeButton} role='button'>{item.testAssigned}</div></td>
                        <td><div className={classes.removeButton} role='button'>View test</div></td>
                    </tr>))}
                </table>}

                {activeTab === 1 && 
                <table className={`w-100 mt-3 ${classes.learnerTable}`}>
                    <tr>
                    {gradeTableHeader.map((item, i) => (
                        <th style={{width: item.width}} key={i}>{item.title}</th>
                    ))}
                    </tr>
                    {gradeTableContent.map((item, i) => (<tr>
                        <td>{item.name}</td>
                        <td><div className={!item.assigned && classes.removeButton} role={!item.assigned && 'button'}>{item.grade}</div></td>
                        <td><div className={classes.removeButton} role='button'>View test</div></td>
                    </tr>))}
                </table>}

                {activeTab === 2 && <div className={classes.chatWrapper}>

                </div>}

            </div>
        </div>
    )
}

export default LiveLesson