// src/services/lessons.js
import { readFiles,readFolders,rest,} from '@directus/sdk';
import directus from './directus';
import Cookies from 'js-cookie';

const client = directus.with(rest());


export const fetchFolders = async () => {
  const token = Cookies.get('authToken');


    if (!token) {
      throw new Error('User not authenticated');
    }
  try {

    const folders = await client.request(readFolders({fields: "*.*", filter: {parent: {name: "Resource library"} }, sort: ["name"]}, {access_token: token}));
    console.log("FOLDERS", folders)
    return folders;
  } catch (error) {
    throw new Error('Failed to fetch files: ' + error.message);
  }
};


export const fetchFiles = async (id) => {
  const token = Cookies.get('authToken');


    if (!token) {
      throw new Error('User not authenticated');
    }
  try {

    const files = await client.request(readFiles({fields: "*.*", filter: {folder: id}}, {access_token: token}));
    console.log("FILES", files)
    return files;
  } catch (error) {
    throw new Error('Failed to fetch files: ' + error.message);
  }
};

