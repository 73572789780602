import classes from './Overlay.module.css'

const Overlay = ({children, onClick}) => {
    return(
        <div className={`d-flex justify-content-center align-items-center ${classes.main}`} onClick={onClick}>
            {children}
        </div>
    )
}

export default Overlay