import classes from './SortItems.module.css'

const SortItems = ({heading}) => {
    return(
        <div className={`${classes.main} d-flex align-items-center justify-content-between`}>
            <h4>{heading}</h4><div className={classes.showType}>Arrange by: date</div>
        </div>
    )
}

export default SortItems