// src/context/LessonsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchClasses } from '../services/classes';




const ClassesContext = createContext();

export const ClassesProvider = ({ children }) => {
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
    

  //   getLessons();
  // }, []);

  

  const getClasses = async () => {
    try {
      const classesData = await fetchClasses();

      console.log("CLASSSES IN CONTEXT", classesData)
      setSchoolClasses(classesData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ClassesContext.Provider value={{ schoolClasses, loading, getClasses, error }}>
      {children}
    </ClassesContext.Provider>
  );
};

export const useClasses = () => useContext(ClassesContext);