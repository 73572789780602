import { useState } from 'react'
import InfoCard from '../UI/InfoCard'
import SearchBar from '../UI/SearchBar'
import VerticalTabs from '../UI/VerticalTabs'
import classes from './Performance.module.css'
import SortItems from '../UI/SortItems'

const infoList = [
    {
        title: "Average grade",
        buttonText: "A"
    },
    {
        title: "Best attendance",
        buttonText: "10th grade C"
    },
    {
        title: "Best performing class",
        buttonText: "9th grade A"
    },
    {
        title: "Worst performing class",
        buttonText: "9th grade C"
    }
]

const tableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Class",
        width: '100px'
    },
    {
        title: "Avg. Grade",
        width: '100px'
    },
    {
        title: "Last grade",
        width: '100px'
    },
    {
        title: "Best grade",
        width: '100px'
    },
    {
        title: "Best performing",
        width: '150px'
    },
    {
        title: "Worst performing",
        width: '150px'
    },
    {
        title: "Actions",
        width: '100px'
    } 
]

const tableContent = [
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "C +",
        bestgrade: "A",
        bestPerforming: "Mathematics",
        worstPerforming: "Technology"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "C +",
        bestgrade: "A",
        bestPerforming: "Mathematics",
        worstPerforming: "Technology"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "C +",
        bestgrade: "A",
        bestPerforming: "Mathematics",
        worstPerforming: "Technology"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "C +",
        bestgrade: "A",
        bestPerforming: "Mathematics",
        worstPerforming: "Technology"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "C +",
        bestgrade: "A",
        bestPerforming: "Mathematics",
        worstPerforming: "Technology"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "C +",
        bestgrade: "A",
        bestPerforming: "Mathematics",
        worstPerforming: "Technology"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "C +",
        bestgrade: "A",
        bestPerforming: "Mathematics",
        worstPerforming: "Technology"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "C +",
        bestgrade: "A",
        bestPerforming: "Mathematics",
        worstPerforming: "Technology"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        avgGrade: "B +",
        lastGrade: "C +",
        bestgrade: "A",
        bestPerforming: "Mathematics",
        worstPerforming: "Technology"
    }
]


const attendanceTableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Class",
        width: '100px'
    },
    {
        title: "Attendance",
        width: '100px'
    },
    {
        title: "Last grade",
        width: '100px'
    },
    {
        title: "Best grade",
        width: '100px'
    }
]

const attendanceTableContent = [
    {
        name: "Learner name",
        class: "9th Grade",
        attendance: "80%",
        lastGrade: "C +",
        bestgrade: "A"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        attendance: "80%",
        lastGrade: "C +",
        bestgrade: "A"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        attendance: "80%",
        lastGrade: "C +",
        bestgrade: "A"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        attendance: "80%",
        lastGrade: "C +",
        bestgrade: "A"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        attendance: "80%",
        lastGrade: "C +",
        bestgrade: "A"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        attendance: "80%",
        lastGrade: "C +",
        bestgrade: "A"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        attendance: "80%",
        lastGrade: "C +",
        bestgrade: "A"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        attendance: "80%",
        lastGrade: "C +",
        bestgrade: "A"
    },
    {
        name: "Learner name",
        class: "9th Grade",
        attendance: "80%",
        lastGrade: "C +",
        bestgrade: "A"
    }
]

const tabs = [
    "Grade performance","Attendance"
 ]

const Performance = () => {
    const [activeTab, setActiveTab] = useState(0)

    return(
        <div className={classes.main}>
            <SearchBar placeholder="Search learners"/>
            <div className='row mt-4'>
                {infoList.map((item, i) => (<div className='col-3'key={i} >
                    <InfoCard title={item.title} buttonText={item.buttonText} />
                </div>))}
            </div>
            <VerticalTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} className='mt-4'/>
            <div className='mt-4'>
            {activeTab === 0 && <><SortItems heading="Learner performance"/>
           <table className={`w-100 mt-3 ${classes.learnerTable}`}>
                        <tr>
                        {tableHeader.map((item, i) => (
                            <th style={{width: item.width}} key={i}>{item.title}</th>
                        ))}
                        </tr>
                        {tableContent.map((item, i) => (<tr>
                            <td><div className={classes.removeButton} role='button'>{item.name}</div></td>
                            <td>{item.class}</td>
                            <td>{item.avgGrade}</td>
                            <td>{item.lastGrade}</td>
                            <td>{item.bestgrade}</td>
                            <td>{item.bestPerforming}</td>
                            <td>{item.worstPerforming}</td>
                            <td><div className={classes.removeButton} role='button'>Assign test</div></td>
                        </tr>))}
                        </table></>}
            {activeTab === 1 && <><SortItems heading="Learner attendance"/>
            <table className={`w-100 mt-3 ${classes.learnerTable}`}>
                        <tr>
                        {attendanceTableHeader.map((item, i) => (
                            <th style={{width: item.width}} key={i}>{item.title}</th>
                        ))}
                        </tr>
                        {attendanceTableContent.map((item, i) => (<tr>
                            <td><div className={classes.removeButton} role='button'>{item.name}</div></td>
                            <td>{item.class}</td>
                            <td>{item.attendance}</td>
                            <td>{item.lastGrade}</td>
                            <td>{item.bestgrade}</td>
                        </tr>))}
                        </table></>}        
            </div>
        </div>
    )
}

export default Performance