import { useNavigate } from "react-router-dom";
import DetailsButton from "./DetailsButton"
import classes from "./InfoCard.module.css"

const InfoCard = ({title, subText, grade, buttonText, link}) => {
    let navigate = useNavigate();
    return(
        <div className={`col d-flex justify-content-between flex-column  ${classes.cardCatainer} `} onClick={() => link && navigate({pathname: link})} role={link && 'button'}>
            <div className='d-flex justify-content-between align-items-center'>
                <div>{title}</div>
                <div>{grade}</div>
            </div>
            {subText && <div className={classes.subText}>{subText}</div>}
            {buttonText && <DetailsButton buttonText={buttonText}/>}

        </div>
    )
}

export default InfoCard