import InfoDetailsCard from '../../UI/InfoDetailsCard'
import classes from './TermsAndConditions.module.css'


const TermsAndConditions = () => {

    return(
        <div className={classes.main}>
            <InfoDetailsCard title="Terms and conditions"/>
        </div>
    )
}

export default TermsAndConditions