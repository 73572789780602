// src/CustomToolbar.js
import React, { useEffect, useState } from 'react';
import classes from '../CustomToolbar/CustomToolbar.module.css'


const CustomToolbarComponent = (toolbar) => {


    useEffect(() => {
        toolbar.onView('day')
    },[])

    
  return (
    <div> 
        <div className="rbc-toolbar d-flex align-items-center justify-content-end gap-3 mb-4">
        {/* <span className="rbc-btn-group">
            <button type="button" onClick={goToBack}>Back</button>
            <button type="button" onClick={goToNext}>Next</button>
        </span> */}
        
        {/* <span className="rbc-toolbar-label">{label()}</span> */}
        {/* <span className="rbc-btn-group">
            <button type="button" onClick={() => toolbar.onView('month')}>Month</button>
            <button type="button" onClick={() => toolbar.onView('week')}>Week</button>
            <button type="button" onClick={() => toolbar.onView('day')}>Day</button>
        </span> */}
        <div className={`${classes.viewButtonsWrapper} d-flex align-items-center`}>
            <div className={`d-flex align-items-center justify-content-center ${classes.dayButton}`} style={{color: toolbar.view === "day" && "#FFFFFF", background: toolbar.view === "day" && "#43B061"}} onClick={() => { toolbar.onView('day'); }} role='button'>
                Day
            </div>
            <div className={`d-flex align-items-center justify-content-center ${classes.weekButton}`} style={{color: toolbar.view === "week" && "#FFFFFF", background: toolbar.view === "week" && "#43B061"}} onClick={() => { toolbar.onView('week'); }} role='button'>
                Week
            </div>
            <div className={`d-flex align-items-center justify-content-center ${classes.monthButton}`} style={{color: toolbar.view === "month" && "#FFFFFF", background: toolbar.view === "month" && "#43B061"}} onClick={() => { toolbar.onView('month'); }} role='button'>
                Month
            </div>

        </div>

        </div>
       
    </div>
  );
};

export default CustomToolbarComponent;