// src/CustomEvent.js
import React from 'react';
import moment from 'moment';
import classes from './DayViewCustomEvent.module.css'
import VideoIcon from '../../../../../../icons/VideoIcon';
import BoardIcon from '../../../../../../icons/BoardIcon';
import { getOrdinal } from '../../../../../../utilities/functions';

const DayViewCustomEvent = ({ event }) => {
  const startTime = moment(event.start).format('HH:mm');
  const endTime = moment(event.end).format('HH:mm');

  return (
    <div className={`d-flex align-items-center gap-3  ${classes.wrapper}`}>
      <div className={`d-flex align-items-center justify-content-center ${classes.iconWrapper}`}>
      {event.lessonsType === "Interactive Lesson" ? <BoardIcon /> : <VideoIcon />}
      </div>
      <div >
      <div className={classes.titleText}>{event.title}</div>
        <div className={classes.subTitleText}>{startTime} - {endTime} / {getOrdinal(event.grade)} grade</div>
      </div>
    </div>
  );
};

export default DayViewCustomEvent;