import CloseIcon from "../../../icons/CloseIcon"
import classes from "./ModalCard.module.css"
const ModalCard = ({children, title, onClose}) => {
    return(
        <div className={classes.newLessonCard} onClick={(event) => event.stopPropagation() }>
                <div className='d-flex justify-content-between align-items-center'><h4>{title}</h4><CloseIcon onClick={onClose} /></div>
                {children}
        </div>
    )
}

export default ModalCard