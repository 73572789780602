import { useState } from 'react'
import classes from './ChangeInformation.module.css'
import InputField from '../../UI/InputField'




const ChangeInformation = () => {

    return(
        <div className={classes.main}>
           <div className='d-flex flex-column gap-4'>
                <InputField type="text" label="First name*" placeholder="Field input" labelBackground="#F5F6FA"/>
                <InputField type="text" label="Last name*" placeholder="Field input" labelBackground="#F5F6FA"/>
                <InputField type="text" label="Email*" placeholder="Field input" labelBackground="#F5F6FA"/>
                <InputField type="text" label="Phone*" placeholder="Field input" labelBackground="#F5F6FA"/>
                <InputField type="text" label="School name" placeholder="Field input" labelBackground="#F5F6FA"/>
                <InputField type="text" label="District" placeholder="Field input" labelBackground="#F5F6FA"/>
                <InputField type="text" label="Circuit" placeholder="Field input" labelBackground="#F5F6FA"/>
                <InputField type="text" label="Grade" placeholder="Field input" labelBackground="#F5F6FA"/>
                <div className={classes.saveButton} role='button'>Save changes</div>
                <div className={classes.cancelButton} role='button'>Cancel</div>
            </div>
        </div>
    )
}

export default ChangeInformation