import { useEffect } from 'react'
import { fetchCurriculum } from '../../services/curriculum'
import SearchBar from '../UI/SearchBar'
import tabeleClasses from '../UI/Table.module.css'
import classes from './Curriculum.module.css'
import { useNavigate } from 'react-router-dom'


const tableHeader = [
    {
        title: "Name",
        width: 'auto'
    },
    {
        title: "Set by",
        width: '150px'
    },
    {
        title: "Actions",
        width: '150px'
    } 
]

const tableContent = [
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 1
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 2
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 3
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 4
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 4
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 6
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 7
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 8
    },
    {
        name: "Category name",
        setBy: "Advisor name",
        id: 9
    }
    
]

const Curriculum = () => {

    let navigate = useNavigate();

    useEffect(() => {
        getCurriculum()
    },[])

    const getCurriculum = async() => {
        await fetchCurriculum()
    }

    return(
        <div className={classes.main}>
            <SearchBar placeholder="Search curriculum"/>
            <div className='mt-4'>
           <table className={`w-100 mt-3 ${tabeleClasses.learnerTable}`}>
                <tr>
                {tableHeader.map((item, i) => (
                    <th style={{width: item.width}} key={i}>{item.title}</th>
                ))}
                </tr>
                {tableContent.map((item, i) => (<tr>
                    <td><div className={tabeleClasses.removeButton} role='button'>{item.name}</div></td>
                    <td>{item.setBy}</td>
                    <td><div className='d-flex align-items-center justify-content-between'><div className={tabeleClasses.removeButton} role='button'>VIEW</div><div className={tabeleClasses.removeButton} role='button'>REMOVE</div></div></td>
                </tr>))}
                </table>
                </div>
        </div>
    )
}

export default Curriculum